var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"blog-edit-wrapper"},[_c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"mt-5"},[_vm._v(" Add New Instructor "),_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1)]),_c('validation-observer',{ref:"addInstructorvalidate"},[_c('b-form',{ref:"form",staticClass:"mt-2",staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Instructor Name","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Instructor Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-title"},model:{value:(_vm.addInstructor.name),callback:function ($$v) {_vm.$set(_vm.addInstructor, "name", $$v)},expression:"addInstructor.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Teaching Field Name","label-for":"blog-edit-slug"}},[_c('validation-provider',{attrs:{"name":"Teaching Field Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-slug"},model:{value:(_vm.addInstructor.field),callback:function ($$v) {_vm.$set(_vm.addInstructor, "field", $$v)},expression:"addInstructor.field"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v("Instructor image")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('div',{staticClass:"d-inline-block"},[_c('b-media-aside',[_c('b-img',{ref:"refPreviewEl",staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":_vm.Tabimage,"height":"110","width":"170"}})],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"rounded ml-1 mt-2 text-center",attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.refPreviewEl.src = _vm.Tabimage
                _vm.addInstructor.tab_image=''}}},[_vm._v(" Remove Image ")])],1),_c('b-media-body',[_c('b-card-text',{staticClass:"mt-1"}),_c('div',{staticClass:"d-inline-block mt-1"},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Instructor Image ","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Instructor Image "},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-file',{ref:"refInputEl",attrs:{"accept":".jpg, .png, .gif, .jpeg","placeholder":"Choose file"},on:{"input":_vm.inputImageRenderer},model:{value:(_vm.addInstructor.tab_image),callback:function ($$v) {_vm.$set(_vm.addInstructor, "tab_image", $$v)},expression:"addInstructor.tab_image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Instructor image Alt Text","label-for":"blog-edit-title"}},[_c('b-form-input',{attrs:{"id":" alt_header_image"},model:{value:(_vm.addInstructor.alt_tab_image),callback:function ($$v) {_vm.$set(_vm.addInstructor, "alt_tab_image", $$v)},expression:"addInstructor.alt_tab_image"}})],1)],1)],1)],1)],1)]),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[(!_vm.isLoading)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Save Changes ")]):_vm._e(),(_vm.isLoading)?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","disabled":""}},[_vm._v(" Loading... "),_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"sr-only"})],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }